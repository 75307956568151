@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed&family=Merriweather&family=Cutive+Mono&family=Droid+Sans&family=Coming+Soon&family=Carrois+Gothic&family=Dancing+Script&family=Vazirmatn:wght@400;700&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", serif;


  font-optical-sizing: auto;
}





body {
  direction: ltr;
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
  font-family: Inter;
}

html,
body {

  overflow-x: hidden;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(5px, 8px);
  }

  50% {
    transform: translate(10px, 0px);
  }

  75% {
    transform: translate(-4px, 2px);
  }

  100% {
    transform: translate(0, 0);
  }
}

#animation1 {
  animation-delay: 0.5s;
  animation: float 4s ease-in-out infinite;
}

#animation2 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 1s;
}

#animation6 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 1.5s;
}

#animation5 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 2s;
}

#animation4 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 2.5s;
}

#animation3 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 3s;
}

#animation7 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 3.5s;
}

#animation8 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 4s;
}

#animation9 {
  animation: float 4s ease-in-out infinite;
  animation-delay: 4.5s;
}

.gradient {
  background: rgb(90, 7, 34);
  background: linear-gradient(29deg,
      rgba(90, 7, 34, 1) 28%,
      rgba(192, 16, 72, 1) 100%);
}

#blur {
  /* From https://css.glass */
  background: rgba(153, 153, 153, 0.27);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.6px);
  -webkit-backdrop-filter: blur(3.6px);
}

/* 
.scrollbar-hidden {
    scrollbar-width: none; 
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none; 
} */

.loader {
  border: 4px solid rgba(255, 255, 255, 0.466);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.loader-2 {
  border: 6px solid rgba(7, 88, 163, 0.466);
  border-top: 6px solid white;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
}

.loader_remove {
  border: 4px solid rgb(218, 25, 25);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* //////loder cube */
.spinner {
  width: 70.4px;
  height: 70.4px;
  color: #f63d68;
  position: relative;
  background: conic-gradient(from 134deg at top, currentColor 92deg, #0000 0) top,
    conic-gradient(from -46deg at bottom, currentColor 92deg, #0000 0) bottom;
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

.spinner:before {
  content: '';
  position: absolute;
  inset: 0;
  background: conic-gradient(from -135deg at top 0 left 37.2px, #0000, currentColor 1deg 90deg, #0000 0),
    conic-gradient(from -45deg at right 0 top 37.2px, #0000, currentColor 1deg 90deg, #0000 0),
    conic-gradient(from 45deg at bottom 0 right 37.2px, #0000, currentColor 1deg 90deg, #0000 0),
    conic-gradient(from 135deg at left 0 bottom 37.2px, #0000, currentColor 1deg 90deg, #0000 0);
  animation: shapes-et721zhg 0.8999999999999999s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes shapes-et721zhg {
  33% {
    inset: -17.6px;
    transform: rotate(0deg);
  }

  66% {
    inset: -17.6px;
    transform: rotate(90deg);
  }

  100% {
    inset: 0;
    transform: rotate(90deg);
  }
}

/* /////// */
.line {
  margin: 8px 0;
}

.line h2 {
  font-size: 15px;
  text-align: center;
  border-bottom: 2px solid #e9eaeb;
}

.line h2 span {
  background-color: white;
  color: #717680;
  position: relative;
  top: 12px;
  padding: 0 7px;
}

.css-13cymwt-control {
  border: none !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

.instagram-bg {
  background: url("../../public/social-media/instagram/instagram.png");
  background-repeat: no-repeat;
  background-size: 540px;
  background-position: top;
}

.whatsapp-bg {
  background: url("../../public/social-media/whatsapp/whatsapp.png");
  background-repeat: no-repeat;
  background-size: 520px;
  background-position: center;
}

.telegram-bg {
  background: url("../../public/social-media/telegram/telegram.png");
  background-repeat: no-repeat;
  background-size: 600px;
  background-position: center;
}

.youtube-bg {
  background: url("../../public/social-media/youtube/youtube.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 650px;
}

.twitter-bg {
  background: url("../../public/social-media/twitter/twitter.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 600px;
}

.snapchat-bg {
  background: url("../../public/social-media/snapchat/snapchat.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 600px;
}

.tiktok-bg {
  background: url("../../public/social-media/tiktok/tiktok.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 500px;
}

.cdbaIj {
  background-color: rgb(277, 27, 84) !important;
}

.kzUtKB[aria-selected="true"] {
  background: rgb(255, 241, 243) !important;
}

.kzUtKB:hover {
  background: rgb(255, 241, 243) !important;
}

.kVHDOT[aria-selected="true"] {
  background: rgb(255, 241, 243) !important;
}

.kVHDOT:hover {
  background: rgb(117, 93, 97) !important;
}

.cropper {
  color: #f63d68 !important;
  background: #000000 !important;


}

@media screen and (max-width:500px) {

  .image-edit-responsive {
    max-width: 380px !important;
    max-height: 450px !important;
  }

}

@media screen and (max-width:450px) {

  .image-edit-responsive {
    max-width: 360px !important;
    max-height: 400px !important;
  }

}

@media screen and (max-width:420px) {
  .sktech {
    top: 100px !important;
  }

  .image-edit-responsive {
    max-width: 350px !important;
    max-height: 380px !important;
  }

}

@media screen and (min-width:500px) {

  .image-edit-responsive {

    max-height: 450px !important;
  }

}

@media screen and (max-width:390px) {
  .addtxtres {
    overflow-x: scroll;
    max-width: 300px;

  }

  .image-edit-responsive {
    max-width: 300px !important;
  }

  .addtxtres::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .addtxtres::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .addtxtres::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .addtxtres::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

.react-mobile-cropper-rotate-control {
  color: #717680 !important;

}

.cls-1 {
  fill: none;
  stroke: #f63d68;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 31
}

@media screen and (max-width: 768px) {

  .instagram-bg,
  .whatsapp-bg,
  .telegram-bg,
  .youtube-bg,
  .twitter-bg,
  .tiktok-bg,
  .snapchat-bg {
    background-image: none;

  }
}


@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

@layer utilities {
  .scrollbar2::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar2::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #ffff;
  }

  .scrollbar2::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar2::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

@layer utilities {
  .no-select {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }
}

.applay {
  width: 20px !important;
}

/* Firefox-specific styles for backdrop blur and scrollbar styling */
@-moz-document url-prefix() {
  .backdrop-blur-xl {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--brand600, #000) var(--brand100, #fff);
  }
}

/* Firefox-specific styles for backdrop filters and scrollbar */
/* These styles are applied only in Firefox to ensure proper rendering of backdrop-blur and scrollbar styling */
@-moz-document url-prefix() {

  /* Apply vendor prefixes for backdrop filters in Firefox */
  .backdrop-blur-md {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }


  /* Firefox-specific scrollbar styling */
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--brand600, #000) var(--brand100, #fff);
  }
}